import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { ClipLoader } from "react-spinners";
import { FaTrashAlt } from "react-icons/fa";
import { ErrorMessage, Formik } from "formik";
import Select from "react-select";

import InputStyled from "../../../../../../../components/styles/InputStyled";
import Table from "../../../../../../../components/styles/Table";
import ButtonDefault from "../../../../../../../components/styles/buttons";
import requests from "../../../../../../../services/requests";
import { colors } from "../../../../../../../assets/colors";
import { getSelectValues } from "../../../../../../../assets/utils/format/formValues";
import Form from "./styles";

export default function ModalForm({ process, onSuccess }) {
    const [source, setSource] = useState();
    const [percent, setPercent] = useState({});
    const [progress, setProgress] = useState();
    const [modalitiesList, setModalitiesList] = useState({});
    const [stagesList, setStagesList] = useState([]);

    const removeTempFile = ({ values, key, setValues, setErrors }) => {
        const form = document.getElementById("form");
        form.reset();
        setErrors({});

        delete values.files[key];
        delete values.stages[key];
        delete values.modalities[key];

        setValues({ ...values });
        setPercent({});
    };

    const handleStage = ({ key, setFieldValue, item }) => {
        const find = Object.values(modalitiesList).find(entity => entity.stage === item?.value && entity.modalities?.length);
        function setValue(_modalities) {
            setFieldValue(`stages[${key}]`, item);
            setFieldValue(`modalities[${key}]`, null);
            if (item) {
                setModalitiesList({
                    ...modalitiesList,
                    [key]: {
                        modalities: _modalities,
                        stage: item?.value,
                    },
                });
            } else {
                delete modalitiesList[key];
                setModalitiesList({
                    ...modalitiesList,
                });
            }
        }

        // api modalities find
        if (find) {
            setValue(find.modalities);
        } else
            requests.getProcessFileModalities(item?.value).then(response => {
                const _modalities = response.data.map(option => {
                    return {
                        value: option.identifier,
                        label: option.description,
                    };
                });

                setValue(_modalities);
            });
    };

    useEffect(() => {
        //Get Stage
        requests.getStagesList().then(response => {
            const _stages = response.data?.map(option => {
                return { value: option.identifier, label: option.description };
            });
            setStagesList(_stages);
        });

        const cancel = Axios.CancelToken;
        setSource(cancel.source);
        return () => {
            onSuccess();
            return source?.cancel();
        };
    }, []);
    useEffect(() => {
        if (progress) {
            setPercent({ ...percent, ...progress });
        }
    }, [progress]);

    return (
        <Formik
            initialValues={{
                files: {},
                stages: {},
                modalities: {},
            }}
            onSubmit={(values, { setFieldError, setSubmitting }) => {
                const { files } = values;
                const modalities = getSelectValues(values.modalities);
                const stages = getSelectValues(values.stages);

                const form = document.getElementById("form");
                form.reset();

                if (!Object.keys(files).length) {
                    setFieldError(`files`, "Click e escolha um arquivo ou arraste.");
                }

                Object.entries(files).forEach(([key, file]) => {
                    const stage = stages[key];
                    const modality = modalities[key];
                    const processCode = process.code?.replace("/", "-").toUpperCase();
                    const processName = file?.name?.substr(0, processCode.length).toUpperCase();
                    let hasErrors = 0;

                    if (file?.type !== "text/xml" && processName !== processCode) {
                        hasErrors++;
                        setFieldError(`files[${key}]`, "nome deve iniciar com cód. do processo");
                    }

                    if (!stage) {
                        hasErrors++;
                        setFieldError(`stages[${key}]`, "campo obrigatório");
                    }
                    if (!modality) {
                        hasErrors++;
                        setFieldError(`modalities[${key}]`, "campo obrigatório");
                    }

                    if (hasErrors === 0) {
                        const config = {
                            onUploadProgress: function (progressEvent) {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setProgress({ [key]: percentCompleted });
                            },
                            cancelToken: source.token,
                        };

                        const docs = new FormData();
                        docs.append("process_file_upload[file]", file);
                        docs.append("process_file_upload[stage]", stage);
                        docs.append("process_file_upload[modality]", modality);

                        requests
                            .saveDocsUploads(process.identifier, docs, config)
                            .then(() => {
                                toast.success(
                                    <div>
                                        {file.name} <br /> enviado com sucesso
                                    </div>
                                );
                                delete values.files[key];
                                delete values.stages[key];
                                delete values.modalities[key];
                            })
                            .catch(() => {
                                toast.error(
                                    <div>
                                        {file.name} <br /> falhou, tente novamente.
                                    </div>
                                );
                            })
                            .finally(() => setSubmitting(false));
                    }
                });

                setSubmitting(false);
            }}
        >
            {({ setValues, handleSubmit, isSubmitting, values, setFieldValue, setErrors }) => (
                <Form id="form" onSubmit={handleSubmit}>
                    <InputStyled
                        name="files"
                        id="files"
                        accept=".pdf, .docx, .xml, .jpeg, .png"
                        multiple={true}
                        onChange={e => {
                            const files = Array.from(e.target?.files);
                            files.forEach(file => {
                                const key = file.name.replaceAll(".", "") + file.lastModified;
                                setFieldValue(`files[${key}]`, file);
                                setFieldValue(`stages[${key}]`, null);
                                setFieldValue(`modalities[${key}]`, null);
                            });
                        }}
                    />
                    {!Object.keys(values.files).length && (
                        <ErrorMessage component="p" className="text-red mb-4 font-light text-xs" name={`files`} />
                    )}

                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Etapa</th>
                                    <th>Tipo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(values.files).map(([key, file]) => (
                                    <tr key={key}>
                                        <td>
                                            {file.name}
                                            <ErrorMessage
                                                component="p"
                                                className="text-red mb-4 font-light text-xs"
                                                name={`files[${key}]`}
                                            />
                                        </td>
                                        <td>
                                            <article>
                                                <Select
                                                    noOptionsMessage={() => "Sem opções"}
                                                    isClearable
                                                    options={stagesList}
                                                    value={values.stages[key]}
                                                    onChange={item => {
                                                        handleStage({ key, setFieldValue, item });
                                                    }}
                                                    placeholder=""
                                                    name={`stage[${key}]`}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red font-light text-xs"
                                                    name={`stages[${key}]`}
                                                />
                                            </article>
                                        </td>
                                        <td>
                                            <article>
                                                <Select
                                                    noOptionsMessage={() => "Escolha uma etapa primeiro"}
                                                    isClearable
                                                    value={values.modalities[key]}
                                                    options={modalitiesList[key]?.modalities || []}
                                                    onChange={item => {
                                                        setFieldValue(`modalities[${key}]`, item);
                                                    }}
                                                    placeholder=""
                                                    name={`modality.[${key}]`}
                                                />
                                                <ErrorMessage
                                                    component="p"
                                                    className="text-red font-light text-xs"
                                                    name={`modalities[${key}]`}
                                                />
                                            </article>
                                        </td>

                                        <td>
                                            <div className="flex items-center">
                                                <button
                                                    className="p-2 rounded-md bg-canal_vermelho"
                                                    type="button"
                                                    onClick={() => removeTempFile({ values, key, setValues, setErrors })}
                                                >
                                                    <FaTrashAlt color="#fff" />
                                                </button>
                                                {percent[key] > 0 && percent[key] < 100 && (
                                                    <>
                                                        <span className="mx-2">{percent[key]}%</span>
                                                        <ClipLoader size={20} color={colors.roxo_oficial} loading={true} />
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <section>
                            <ButtonDefault disabled={isSubmitting} type="submit">
                                {isSubmitting ? (
                                    <div>
                                        <ClipLoader size={20} color={colors.roxo_oficial} loading={true} />
                                    </div>
                                ) : (
                                    "Anexar"
                                )}
                            </ButtonDefault>
                        </section>
                    </>
                </Form>
            )}
        </Formik>
    );
}
